/// <reference types="google.accounts" />

import { createContext, JSX, onMount } from "solid-js";
import Cookies from "js-cookie";

import Range from "@repo/utils/Range";
import UFunction from "@repo/utils/UFunction";
import UHTMLScriptElement from "@repo/utils-client/UHTMLScriptElement";
import USolid from "@repo/utils-solid/USolid";

namespace GoogleSignIn {
  export let callback: google.accounts.id.IdConfiguration["callback"];

  export const load = UFunction.memo((clientId: string, apiDomain: string) =>
    UHTMLScriptElement.inject("https://accounts.google.com/gsi/client").then(
      () => {
        if (!google.accounts.id)
          throw new Error("Google Sign In failed to load.");

        const nonce = crypto.randomUUID();

        const commonCookieAttributes = {
          sameSite: "Lax",
          secure: true,
        } satisfies Cookies.CookieAttributes;

        const domainLevels = apiDomain.split(".").slice(1);
        for (const i of Range(domainLevels.length - 1)) {
          Cookies.remove("k_csrf_token", {
            domain: domainLevels.slice(i).join("."),
            ...commonCookieAttributes,
          });
        }

        Cookies.set("k_csrf_token", nonce, {
          domain: apiDomain,
          ...commonCookieAttributes,
        });

        google.accounts.id.initialize({
          client_id: clientId,
          callback,
          nonce,
        });
        return google.accounts.id;
      },
    ),
  );

  export declare namespace Button {
    type Props = {};
  }

  export function Button({}: D<Button.Props>) {
    const client = useContext();

    let ref: HTMLDivElement;

    onMount(async () => {
      (await client.load()).renderButton(ref, {
        type: "standard",
        theme: "outline",
        size: "large",
        shape: "pill",
        text: "signin_with",
      });
    });

    return <div ref={ref!} />;
  }

  const Context = createContext<
    Omit<Provider.Props, "children" | "apiDomain"> & {
      load(): Promise<typeof google.accounts.id>;
    }
  >();
  export declare namespace Provider {
    type Props = {
      clientId: string;
      apiDomain: string;
      children?: JSX.Element;
    };
  }
  export function Provider({
    clientId,
    apiDomain,
    children,
  }: D<Provider.Props>) {
    return (
      <Context.Provider
        value={{ load: () => load(clientId, apiDomain), clientId }}
      >
        {children}
      </Context.Provider>
    );
  }
  export function useContext() {
    const context = USolid.useContext(Context);

    if (!context)
      throw new Error(
        "Cannot call GoogleSso.useContext outside of GoogleSso.Provider.",
      );

    return context;
  }
}

export default GoogleSignIn;
